<template>
  <!-- Main file representing all meetings page (index) -->
  <div class="flex flex-col flex-grow justify-between lg:ml-12">
    <m-button
      class="fixed z-50 m-5 bottom-0 right-0 lg:hidden"
      button-style="btn-primary"
      segmentName="New note"
      @click="createNote"
      icon="✍️"
      :pending="newMeetingPending"
      data-tooltip="new-note-button-mobile"
    >
      New meet
    </m-button>
    <hover-tool-tip
      label="Create an instant meeting note, added<br>to your calendar automatically"
      selector='[data-tooltip="new-note-button-mobile"]'
      placement="top"
      url="https://help.meetric.app/en/articles/5150625-how-do-i-change-the-name-of-my-new-note"
    ></hover-tool-tip>
    <div class="bg-transparent sticky pt-2 lg:pt-0 top-0 z-30">
      <div class="bg-basebackground">
        <div class="h-10 block lg:hidden"></div>
        <user-header>
          <m-button
            class="m-3"
            button-style="btn-primary"
            segmentName="New note"
            @click="createNote"
            icon="✍️"
            :pending="newMeetingPending"
            data-tooltip="new-note-button"
          >
            Create new meeting note
          </m-button>
          <hover-tool-tip
            label="Create an instant meeting note, added to your calendar automatically"
            selector='[data-tooltip="new-note-button"]'
            placement="bottom"
            url="https://help.meetric.app/en/articles/5150625-how-do-i-change-the-name-of-my-new-note"
          ></hover-tool-tip>
        </user-header>
      </div>
      <!-- <div class="text-lg lg:text-base">
        <div>
          <m-menu-panel :highlight="false">
            <div class="flex flex-grow">
              <div class="flex flex-grow">
                <div class="flex mr-4 lg:mr-8">
                  <div
                    class="flex cursor-pointer group"
                    @click="toNextMeeting++"
                  >
                    <div class="mt-0.5 lg:mt-px">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        class="svg-size-menu fill-current text-base2"
                      >
                        <g>
                          <path
                            d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                          />
                        </g>
                      </svg>
                    </div>
                    <div
                      class="hoverfilter text-grey3 font-medium ml-1 lg:ml-2"
                      title="Scroll to your next meeting"
                    >
                      Next
                      <span class="hidden lg:inline">meeting</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </m-menu-panel>
        </div>
      </div> -->
      <div class="h-3 lg:h-3 bottom-fade"></div>
    </div>
    <MeetingsList :toNextMeeting="toNextMeeting"></MeetingsList>
  </div>
</template>

<script>
import UserHeader from '@/components/UserHeader';
// import MMenuPanel from '@/components/MMenuPanel';

import MButton from '@/components/UI/MButton';
import MeetingsList from './MeetingsList';
import HoverToolTip from '@/components/UI/HoverToolTip';
import { createMeeting } from '@/api/Cherry';
import { redirWhenEventExists } from '@/components/Utils';

export default {
  name: 'Meetings',
  components: {
    UserHeader,
    // MMenuPanel,
    MButton,
    HoverToolTip,
    MeetingsList,
  },
  data() {
    return {
      // filterHidden: 'hidden',

      toNextMeeting: 0, // value not important but changing this will rerender child
      newMeetingPending: false,
    };
  },

  mounted() {},
  methods: {
    async createNote() {
      this.newMeetingPending = true;
      const response = await createMeeting();

      if (response && response.id) {
        // we got response, check and redirect if available
        const resp = await redirWhenEventExists('primary', response.id);
        if (!resp) {
          this.$snack("We couldn't create new meeting");
          this.newMeetingPending = false;
        }
      }
      // leave Pending true so the button is not clickable until page is changed
    },
  },
};
</script>
<style scoped>
.bottom-fade {
  background: linear-gradient(
    180deg,
    rgba(var(--basebackground), 1) 0%,
    rgba(var(--basebackground), 0) 100%
  );
}

#search-input:focus::placeholder {
  color: transparent;
}
.svg-size-menu {
  width: 1.2rem;
  height: 1.2rem;
}

.hoverfilter {
  @apply group-hover:text-grey2 group-hover:opacity-75;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
}
.loader {
  position: relative;
  border-top: 0.15rem solid rgba(0, 164, 189, 0.2);
  border-right: 0.15rem solid rgba(0, 164, 189, 0.2);
  border-bottom: 0.15rem solid rgba(0, 164, 189, 0.2);
  border-left: 0.15rem solid rgba(0, 164, 189, 1);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
